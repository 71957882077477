import React from 'react';
import loadable from '@loadable/component';

import { bool, object, array } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';
import SectionCarouselField from '../../containers/PageBuilder/SectionBuilder/SectionCarouselField';
import SectionCustomArticle from '../../containers/PageBuilder/SectionBuilder/SectionCustomArticle';
import SectionCustomBgVideo from '../PageBuilder/SectionBuilder/SectionCustomPopularLocation';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';
import LandingPageRedesigned from './LandingPageRedesigned/LandingPageRedesigned';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const LandingPageComponent = props => {
  const {
    hotPropertiesListings,
    pageAssetsData,
    inProgress,
    error,
    topReviewedListings,
    randomCampingAvailableListings,
  } = props;

  const setSectionCarouselFieldProps = props => {
    switch (props.sectionId) {
      case 'top-reviews':
        return topReviewedListings;

      case 'hot-properties':
        return hotPropertiesListings;

      default:
        return [];
    }
  };

  const setSectionArticleFieldProps = props => {
    switch (props.sectionId) {
      case 'overnight-camping':
        return randomCampingAvailableListings;

      default:
        return {};
    }
  };

  const sectionOverrides = {
    carousel: {
      component: props => (
        <SectionCarouselField {...props} cardsData={setSectionCarouselFieldProps(props)} />
      ),
    },
    article: {
      component: props => (
        <SectionCustomArticle {...props} cardData={setSectionArticleFieldProps(props)} />
      ),
    },
    columns: { component: SectionCustomBgVideo },
  };

  const history = useHistory();
  const location = useLocation();
  return (
    <LandingPageRedesigned
      assetName={ASSET_NAME}
      pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
      inProgress={inProgress}
      error={error}
      options={{ sectionComponents: sectionOverrides }}
      fallbackPage={<FallbackPage error={error} />}
      location={location}
      history={history}
    />
    // <PageBuilder
    //   assetName={ASSET_NAME}
    //   pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
    //   inProgress={inProgress}
    //   error={error}
    //   options={{ sectionComponents: sectionOverrides }}
    //   fallbackPage={<FallbackPage error={error} />}
    //   location={location}
    //   history={history}
    // />
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
  history: object,
  location: object.isRequired,
  currentSearchParams: object,
  topReviewedListings: array,
  fetchTopReviewedInProgress: bool.isRequired,
  fetchTopReviewedError: propTypes.error,
  hotPropertiesListings: array,
  fetchHotPropertiesInProgress: bool.isRequired,
  fetchHotPropertiesError: propTypes.error,
  randomCampingAvailableListings: array,
  fetchRandomCampingAvailableListingsInProgress: bool.isRequired,
  fetchRandomCampingAvailableListingsError: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  const {
    topReviewedListings,
    fetchTopReviewedInProgress,
    fetchTopReviewedError,
    hotPropertiesListings,
    fetchHotPropertiesInProgress,
    fetchHotPropertiesError,
    randomCampingAvailableListings,
    fetchRandomCampingAvailableListingsInProgress,
    fetchRandomCampingAvailableListingsError,
  } = state.LandingPage || {};
  return {
    pageAssetsData,
    inProgress,
    error,
    topReviewedListings,
    fetchTopReviewedInProgress,
    fetchTopReviewedError,
    hotPropertiesListings,
    fetchHotPropertiesInProgress,
    fetchHotPropertiesError,
    randomCampingAvailableListings,
    fetchRandomCampingAvailableListingsInProgress,
    fetchRandomCampingAvailableListingsError,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
