import theEncampmentEscape from '../images/sectionPortfolio/theEncampmentEscape.png';
import diamondArrow from '../images/sectionPortfolio/diamondArrow.png';
import crystalRiver from '../images/sectionPortfolio/crystalRiver.jpg';
import southBoulderCanyon from '../images/sectionPortfolio/southBoulderCanyon.jpg';
import arkansasHeadwatersGetaway from '../images/sectionPortfolio/arkansasHeadwatersGetaway.jpg';
import mirrorOnTheMichigan from '../images/sectionPortfolio/mirrorOnTheMichigan.jpg';
import windingWhiteRiver from '../images/sectionPortfolio/windingWhiteRiver.jpg';
import bearRiverScenicGetaway from '../images/sectionPortfolio/bearRiverScenicGetaway.jpg';

class PortfolioItem {
  constructor(image, title, state, description, listingId, contentTitle) {
    this.image = image;
    this.title = title;
    this.state = state;
    this.description = description;
    this.listingId = listingId;
    this.contentTitle = contentTitle;
  }
}

const portfolioData = new Array(
  new PortfolioItem(
    theEncampmentEscape,
    'The Encampment Escape',
    'Wyoming',
    'Private access to the world-famous Encampment River in southern Wyoming',
    `6602d3d4-cfa5-4f17-a1e8-048374991e7e`,
    `the-encampment-escape`
  ),
  new PortfolioItem(
    diamondArrow,
    'Diamond Arrow',
    'Colorado',
    'A beautiful ranch with access to over 1 mile of the world-famous Roaring Fork River.',
    `6602d3d4-d99e-4d53-91f1-4c7224d9e6f1`,
    `diamond-arrow`
  ),
  new PortfolioItem(
    crystalRiver,
    'Crystal River Redstone Preserve',
    'Colorado',
    'Enjoy 1 mile of access on the Crystal River near the picturesque Elk Mountains.',
    `6602d3d4-a203-43e8-8cd1-ebef00a87749`,
    `crystal-river-redstone-preserve`
  ),
  new PortfolioItem(
    southBoulderCanyon,
    'South Boulder Canyon',
    'Colorado',
    'Only an hour’s drive from Denver is a mile of private access on South Boulder Creek.',
    `66c66cc3-dcbb-408e-9dbe-201e7ace0c65`,
    `south-boulder-canyon`
  ),
  new PortfolioItem(
    arkansasHeadwatersGetaway,
    'Arkansas Headwaters Getaway',
    'Colorado',
    'This beautiful property includes approximately 3/4 mile of gold medal water on the world-famous Arkansas River.',
    `6602d3d4-9af5-4b89-8c1d-5c1965434370`,
    `arkansas-headwaters-getaway`
  ),
  new PortfolioItem(
    mirrorOnTheMichigan,
    'Mirror on the Michigan',
    'Colorado',
    'Nestled at over 10,500 feet in a high mountain valley near the Continental Divide in South Park, this 7.5 acre lake is a mountaintop treat.',
    `66a02d7f-c1eb-47bc-92e6-31f8920e2743`,
    `mirror-on-the-michigan`
  ),
  new PortfolioItem(
    windingWhiteRiver,
    'Winding White River',
    'Colorado',
    'Premier destination for wild trout fishing on 1.5 miles of the White River near Meeker, CO.',
    `66aaa663-7a77-4e9c-871c-7a1f115244f1`,
    `winding-white-river`
  ),
  new PortfolioItem(
    bearRiverScenicGetaway,
    'Bear River Scenic Getaway',
    'Wyoming',
    'Enjoy a diverse range of trout species on the Bear River near Evanston, Wyoming.',
    `6602d3d4-ebcd-4767-bb9e-5b309379e8da`,
    `bear-river-scenic-getaway`
  )
);

export default portfolioData;
