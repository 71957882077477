import { CustomerComment, NamedLink, PrimaryButton } from '../../../components';
import css from './LandingPageRedesigned.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
// import Slider from 'react-slick';
import PortfolioGrid from './PortfolioGrid/PortfolioGrid';
import StaticPage from '../../PageBuilder/StaticPage';
import TopbarContainer from '../../TopbarContainer/TopbarContainer';
import FooterContainer from '../../FooterContainer/FooterContainer';
import LayoutLandingPage from '../../../components/LayoutComposer/LayoutLandingPage/LayoutLandingPage';

import sectionWorldClassCollage from './images/sectionWorldClass/sectionWorldClassCollage.png';
// import slideshowImage1 from './images/sectionSlideshow/slideshowImage1.png';

import { getMetadata } from '../../PageBuilder/PageBuilder';
import customerReviewsData from './data/customerReviewsData';
import portfolioData from './data/portfolioData';
import LandingPageHeroSection from '../LandingPageHeroSection/LandingPageHeroSection';

const LandingPageRedesigned = injectIntl(props => {
  const {
    intl,
    location,
    history,
    currentSearchParams,
    pageAssetsData,
    inProgress,
    error,
    fallbackPage,
    schemaType,
    options,
    ...pageProps
  } = props;

  const { meta = {} } = pageAssetsData || {};
  const pageMetaProps = getMetadata(meta, schemaType, options?.fieldComponents);

  const benefitsRow1 = [
    {
      className: css.colBenefit,
      titleId: 'LandingPage.sectionBenefits.item1.title',
      descriptionId: 'LandingPage.sectionBenefits.item1.description',
    },
    {
      className: css.colBenefit,
      titleId: 'LandingPage.sectionBenefits.item2.title',
      descriptionId: 'LandingPage.sectionBenefits.item2.description',
    },
  ];
  const benefitsRow2 = [
    {
      className: css.colBenefit,
      titleId: 'LandingPage.sectionBenefits.item3.title',
      descriptionId: 'LandingPage.sectionBenefits.item3.description',
    },
    {
      className: css.colBenefit,
      titleId: 'LandingPage.sectionBenefits.item4.title',
      descriptionId: 'LandingPage.sectionBenefits.item4.description',
    },
  ];
  const benefitsRow3 = [
    {
      className: css.colBenefit,
      titleId: 'LandingPage.sectionBenefits.item5.title',
      descriptionId: 'LandingPage.sectionBenefits.item5.description',
    },
  ];
  const benefitsCombined = [...benefitsRow1, ...benefitsRow2, ...benefitsRow3];

  const generalBenefits = benefitsList => {
    return benefitsList.map((item, index) => (
      <div key={index} className={item.className}>
        <div key={item.id} className={css.propertyItemRow1}>
          <h2 className={css.benefitsTitle}>
            <FormattedMessage id={item.titleId} />
          </h2>
          <p className={css.benefitsDescription}>
            <FormattedMessage id={item.descriptionId} />
          </p>
        </div>
      </div>
    ));
  };

  const worldClassContent = (
    <div className={css.content}>
      <h2 className={css.title}>
        <FormattedMessage id="LandingPage.sectionWorldClass.title" />
      </h2>
      <p className={css.description}>
        <FormattedMessage id="LandingPage.sectionWorldClass.description" />
      </p>
      <NamedLink className={css.namedLink} name="SeasonPassPage">
        <PrimaryButton className={css.exploreMembershipButton}>
          <FormattedMessage id="LandingPage.sectionWorldClass.exploreMembershipButton" />
        </PrimaryButton>
      </NamedLink>
    </div>
  );

  const worldClassCollageElement = (
    <div className={css.sectionWorldClassCollageWrapper}>
      <img src={sectionWorldClassCollage} alt="sectionWorldClassCollage" />
    </div>
  );

  //   const settings = {
  //     dots: false,
  //     autoplay: true,
  //     infinite: true,
  //     autoplaySpeed: 10000,
  //     speed: 500,
  //     slidesToShow: 1,
  //     slidesToScroll: 1,
  //     arrows: true,
  //     responsive: [{}],
  //   };

  //   const slideshowImages = [slideshowImage1];

  return (
    <StaticPage {...pageMetaProps} {...pageProps}>
      <LayoutLandingPage
        className={css.layoutColumStyle}
        topbar={<TopbarContainer />}
        footer={<FooterContainer />}
        display={'inline'}
      >
        <div className={css.root}>
          <LandingPageHeroSection />
          {/* <section className={css.sectionHero}>
            <div className={css.heroFrameWrapper}>
              <div className={css.heroGroup}>
                <div className={css.heroBackdrop}>
                  <div className={css.heroContent}>
                    <div className={css.rowUnsetMarginLR}>
                      <div className={css.colContent}>
                        <h1 className={css.title}>
                          <FormattedMessage id="LandingPage.sectionHero.title" />
                        </h1>
                        <p className={css.subTitle}>
                          <FormattedMessage id="LandingPage.sectionHero.subTitle" />
                        </p>
                        <NamedLink className={css.namedLink} name="SeasonPassPage">
                          <PrimaryButton className={css.becomeAMemberButton}>
                            <FormattedMessage id="LandingPage.sectionHero.becomeAMemberButton" />
                          </PrimaryButton>
                        </NamedLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          <section className={css.sectionBenefits}>
            <div className={css.row}>
              <div className={css.col12}>
                <h1 className={css.title}>
                  <FormattedMessage id="LandingPage.sectionBenefits.title.part1" />
                </h1>
                <h1 className={css.membershipTitle}>
                  <FormattedMessage id="LandingPage.sectionBenefits.title.part2" />
                </h1>
              </div>
            </div>
            <div className={css.desktop}>
              <div className={css.row}>{generalBenefits(benefitsRow1)}</div>
              <div className={css.row}>{generalBenefits(benefitsRow2)}</div>
              <div className={css.row}>
                <div className={css.colBenefit} style={{ margin: '0 auto' }}>
                  <div className={css.propertyItemRow1}>
                    <h2 className={css.benefitsTitle}>
                      <FormattedMessage id="LandingPage.sectionBenefits.item5.title" />
                    </h2>
                    <p className={css.benefitsDescription}>
                      <FormattedMessage id="LandingPage.sectionBenefits.item5.description" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={css.mobile}>
              <div className={css.row}>{generalBenefits(benefitsCombined)}</div>
            </div>
            <div className={css.row}>
              <div className={css.col12}>
                <NamedLink className={css.namedLink} name="SeasonPassPage">
                  <PrimaryButton className={css.exploreMembershipButton}>
                    <FormattedMessage id="LandingPage.sectionBenefits.exploreMembershipButton" />
                  </PrimaryButton>
                </NamedLink>
              </div>
            </div>
          </section>
          <section className={css.sectionPortfolio}>
            <PortfolioGrid
              title={'listings'}
              backgroundColorRoot={'#E7F1F7'}
              cardsData={portfolioData}
              alignItems={'center'}
              textAlign="center"
              backgroundColor={'#E7F1F7'}
            />
          </section>
          <section className={css.sectionReviews}>
            <CustomerComment
              title={null}
              contentTitle={intl.formatMessage({ id: 'LandingPage.customerCommentTitle' })}
              backgroundColorRoot={'#E7F1F7'}
              customerData={customerReviewsData}
              showQuotationImage={true}
              autoplaySpeed={4000}
            />
          </section>
          <section className={css.sectionWorldClass}>
            <div className={css.worldClassContainer}>
              <div className={css.desktop}>
                <div className={css.row}>
                  <div className={css.col6}>{worldClassContent}</div>
                  <div className={css.col6}>{worldClassCollageElement}</div>
                </div>
              </div>
              <div className={css.mobile}>
                <div className={css.row}>
                  <div className={css.col12}>{worldClassCollageElement}</div>
                  <div className={css.col12}>{worldClassContent}</div>
                </div>
              </div>
            </div>
          </section>

          {/* <section className={css.sectionSlideshow}>
            <div className={css.row}>
              <div className={css.col12}>
                <Slider {...settings}>
                  {slideshowImages?.map((image, index) => {
                    return <img key={index} src={image} />;
                  })}
                </Slider>
              </div>
            </div>
          </section> */}
          {/* <section className={css.sectionSlideshow}>
            <div className={css.row}>
              <div className={css.col12}>
                <div className={css.slideshowWrapper}>
                  <div className={css.slideshowGroup}>
                    <div className={css.slideshowBackdrop}>
                      <h1>
                        <FormattedMessage id="LandingPage.sectionSlideshow.title" />
                      </h1>
                      <div className={css.blueRibbon}>
                        <FormattedMessage id="LandingPage.sectionSlideshow.description" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
        </div>
      </LayoutLandingPage>
    </StaticPage>
  );
});

export default LandingPageRedesigned;
