class CustomerReviewItem {
  constructor(customerAvatar, customerComment, customerName, customerSince) {
    this.customerAvatar = customerAvatar;
    this.customerComment = `"${customerComment}"`;
    this.customerName = customerName;
    this.customerSince = customerSince;
  }
}

const customerReviewsData = new Array(
  new CustomerReviewItem(
    null,
    `I began my 2024 season at Diamond Arrow, had a great experience and vowed to return. Camping riverside, immersing in it, and having varied waters to explore make this a magical place. The hosts are warm & welcoming, and have invested in several habitat and property improvements since I was last there. I look forward to experiencing this amazing site again next year. It's good medicine.`,
    'Jeff W.',
    '2024'
  ),
  new CustomerReviewItem(
    null,
    `This was our second time to the Crystal River Redstone Preserve in October (2023 & 2024). Both times it fished well and that's why we wanted to try it again.Two of us fished it this October 2024 and caught at least 25 trout.The biggest was 20 inches and 18 inches.The rest averaged between 10 and 14 inches.This is a beautiful and quiet area to fish.Not very wide which gives you the opportunity to fish runs and holes easily.The water was low and clear but we were able to find fish in riffles, runs and holes.`,
    'Kenny K.',
    '2024'
  ),
  new CustomerReviewItem(
    null,
    `The Rare Waters team hit it out of the park with this special place! Spent the morning casting dry flies to willing fish. A Hopper and a small parachute Adams was the ticket, fish rising everywhere. I caught rainbows and brookies. There are some absolute tanks cruising the shallows. Met Kevin and his daughter who couldn't be nicer and very helpful. They have a couple boats to take out, but I opted to walk the bank which is very wadeable. Looking forward to going back to catch a Palamino on my next visit.`,
    'Jeff J.',
    '2024'
  ),
  new CustomerReviewItem(
    null,
    `I recently had an opportunity to fish on this property. In a word it is extraordinary! The river here is beautiful with an abundance of great habitat improvements. And clearly there is an abundance of fish in this stretch. I don’t keep exact track of these things but I’m sure I caught at least 15 fish, and several others that were ‘long distance releases’. What worked best for me was a hopper/dropper rig with a Chubby (size 12) and a small bh pheasant tail on the dropper. The fish were hitting both the chubby and the nymph. For a short time there was a hatch of small baetis so I switched to a small dry fly and got a few on that. All in all had a great time and would definitely fish here again.`,
    'Doug F.',
    '2024'
  ),
  new CustomerReviewItem(
    null,
    `Favorite place visited so far both of the Gulch locations produced a lot of fish this location had some larger fish and fooled 4 Cuts over 18in and 20+ fish 10to 12in switched from dry fly to Streamer to hunt for some hogs and couldn't keep the smaller Cuts from striking my indicator great problem to have Great place to fish.`,
    'David V.',
    '2024'
  ),
  new CustomerReviewItem(
    null,
    `Fun creek to fish. Fished with dry/dropper to start then just with a dry fly as all the strikes were on the dry. There are a bunch of nice holes and pockets from the parking area to the downstream boundary. Didn’t explore too far upstream. Fish in every hole. Landed 10 fish ranging from 8-13 inches. I’d definitely fish this property again.`,
    'Owen S.',
    '2024'
  ),
  new CustomerReviewItem(
    null,
    `My buddy and I really enjoyed fishing the "Serene South Platte" property. Beautiful area at 10,000' elevation, about 25 degF cooler than Denver Metro. The water was very clear. A very nice and enjoyable section of river to fish. Easy to wade and cross the river when desirable. The hopper dropper setup worked fantastic! Caught most fish on top with the hopper all day long. Browns and rainbows both were very aggressive at hitting the hopper. They were not big, but were a lot of fun. I did catch 4 browns that ran about 14". It was great to be only 10 min from Fairplay so we drove in there for lunch, then back out for more fishing!`,
    'Sam F.',
    '2024'
  ),
  new CustomerReviewItem(
    null,
    `I've gotta say we had an absolute blast at this location! Consistent action throughout the day off the drifty. Fish initially caught on streamers (black and olive) fished on intermediate and full sink. Switched over to big hoppers (just not to not stare at an indicator) trailing a scud and balanced leech. Immediate hookups on both droppers. Ran out of scuds and a chironomid dropper worked well. To the point that we tangled lines on backcast and a fish took the pattern dangling off the boat while we unwrapped lines! The largest was taped at 25-26 inches and none under 18. Water clarity was a bit murky due to algae and chop on the water increased the activity. Fish fight hard so be sure to revive them properly and leave your 5 wts and 4x at home. 6s and 8s on heavy tippet helped land fish quickly adding to our enjoyment and saving the fish from exhaustion! The hosts were fantastic, facilities were clean and we can't wait to go back!`,
    'Vladimir A.',
    '2024'
  ),
  new CustomerReviewItem(
    null,
    `Absolutely incredible! Lots of big Brown and some Rainbow using caddis or BWO. The hosts were very helpful. I MUST go again.`,
    'Dave B.',
    '2024'
  ),
  new CustomerReviewItem(
    null,
    `Beautiful, intelligently improved stretch of the S Arkansas above Salida. Healthy population of browns 10-18" throughout. Active top-water takes all day on dry-dropper rigs. Incredibly fortunate to spend quality time on this 7th generation working ranch placed into land conservation trust -- tip of the cap to the Hutchinson family. Respect.`,
    'Dave L.',
    '2024'
  ),
  new CustomerReviewItem(
    null,
    `2 of us fished this property on Tuesday July 9th. Fishing was great. We fished hoppers and droppers and caught fish on both. Mostly smaller 8 to 12 inch fish and we did best using smaller hoppers and midges. Really nice employees on the ranch. Great day..`,
    'Rudy D.',
    '2024'
  )
);

export default customerReviewsData;
