import React from 'react';
import { func, shape, string } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import css from './LandingPageHeroSection.module.scss';
import { NamedLink, PrimaryButton } from '../../../components';

const LandingPageHeroSection = () => {
  return (
    <section className={css.root}>
      <div className={css.videoContainer}>
        <video
          className={css.videoBgLanding}
          loop
          muted={true}
          autoPlay={true}
          playsInline={true}
          id="rareWatersBgVideo"
          src="/static/media/background-video_1.mp4"
        ></video>
        <div className={css.opacityImg}></div>
      </div>
      <div className={css.sectionContent}>
        <div className={css.opacityImg}></div>
        <div className={css.bgImgContent}>
          <div className={css.row}>
            <div className={css.col12}>
              <h3 className={css.title}>
                <FormattedMessage id="LandingPage.sectionHero.title" />
              </h3>
            </div>
            <div className={css.col12}>
              <h5 className={css.subTitle}>
                <FormattedMessage id="LandingPage.sectionHero.subTitle" />
              </h5>
            </div>
            <div className={css.col12}>
              <NamedLink className={css.namedLink} name="SeasonPassPage">
                <PrimaryButton className={css.becomeAMemberButton}>
                  <FormattedMessage id="LandingPage.sectionHero.becomeAMemberButton" />
                </PrimaryButton>
              </NamedLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

LandingPageHeroSection.defaultProps = {
  rootClassName: null,
  className: null,
  initialSearchFormValues: {},
};

LandingPageHeroSection.propTypes = {
  rootClassName: string,
  className: string,
  history: shape({
    push: func.isRequired,
  }),
  location: shape({
    search: string.isRequired,
  }),
};

export default LandingPageHeroSection;
