import React from 'react';
import PropTypes, { object, bool } from 'prop-types';
import Slider from 'react-slick';
import css from './PortfolioGrid.module.scss';
import parentCss from '../LandingPageRedesigned.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { intlShape } from '../../../../util/reactIntl';
import { NamedLink } from '../../../../components';
import { createSlug } from '../../../../util/urlHelpers';

const PortfolioGrid = props => {
  const { cardsData } = props;

  const settings = {
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: true,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 325,
        settings: {
          arrows: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={css.sliderContainer}>
      <h1 className={parentCss.title}>
        <FormattedMessage id="LandingPage.sectionPortfolio.title" />
      </h1>
      <h2 className={parentCss.subTitle}>
        <FormattedMessage id="LandingPage.sectionPortfolio.subTitle" />
      </h2>
      <Slider {...settings}>
        {cardsData.map((item, index) => (
          <div key={index}>
            <NamedLink
              className={css.namedListingLink}
              key={item.listingId}
              name="ListingPage"
              params={{ id: item.listingId, slug: createSlug(item?.contentTitle || '') }}
            >
              <div className={parentCss.listingCardItem}>
                <img src={item.image} />
                <div className={parentCss.content}>
                  <h3>
                    {item.title} <span className={parentCss.state}>| {item.state}</span>
                  </h3>
                  <p>{item.description}</p>
                </div>
              </div>
            </NamedLink>
          </div>
        ))}
      </Slider>
    </div>
    // )
  );
};

PortfolioGrid.defaultProps = {
  rootClassName: null,
  className: null,
  backgroundColor: 'white',
  backgroundColorRoot: '#E7F1F7',
  title: null,
  titleColor: 'var(--colorBlueTextDark)',
  textAlign: 'left',
  alignItems: null,
  contentTitle: null,
  backgroundImage: null,
  content: null,
  reviews: null,
  cardsData: [],
  withReview: true,
};

const { string, number, arrayOf } = PropTypes;

PortfolioGrid.propTypes = {
  rootClassName: string,
  className: string,
  backgroundColor: string,
  backgroundColorRoot: string,
  title: string,
  textAlign: string,
  alignItems: string,
  titleColor: string,
  contentTitle: string,
  backgroundImage: string,
  content: string,
  cardsData: arrayOf(object).isRequired,
  intl: intlShape.isRequired,
  withReview: bool,
};

export default injectIntl(PortfolioGrid);
